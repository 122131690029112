import './App.css';

function App() {
  return (
      <div>
          <div className="background-one">
              <div className="intro">
                  <h1 className="title">A Cruel Wind Blows</h1>
                  <p className="subtitle">A documentary on on the lost legacy of Soviet Russia’s nuclear program.</p>
                  <a href={"https://www.youtube.com/watch?v=tPImNpbsCPA"}>
                      <button className="button">Watch on Youtube</button>
                  </a>
                  <a href={"https://www.amazon.com/Cruel-Wind-Blows-Rob-King/dp/B089DNB455"}>
                    <button className="button">Stream on Amazon</button>
                  </a>
              </div>
          </div>
          <div className="background-two">
              <div className="summary">
                  <h2 className="title-2"><i>A lost chapter in Soviet history revealed</i></h2>
                  <p>Between 1949 and 1989, the Soviet Union set off 500 nuclear test bombs in northeastern Kazakhstan.
                      The secret program created one of the most radioactive places on earth. Nearby villages were never
                      evacuated. Generations continue to suffer from horrifying disease and mutations in the aftermath
                      of the deadly experiment--one that rivals the destruction and tragedy of Chernobyl.</p>
                  <p><i>A Cruel Wind Blows</i> (originally known as <i>Silent Bombs</i>) is a documentary shot in and around the site where these bombs were
                      detonated, known by the nearby villagers as ‘The Polygon’. The film unravels the story of the
                      Russian government’s experiments while revealing the physiological and psychological damage done to the
                      peoples of the nearby villages.</p>
                  <p>The film is available to stream on Amazon Direct Video in the US and UK.</p>
                  <a href={"https://www.dailymotion.com/video/x8i4n99"}>
                    <button className="button-trailer">Watch the trailer</button>
                  </a>
              </div>
          </div>
          <div className={"background-three"}>
              <div className={'contactBox'}>
                  <div className={'contactTitle'}>Contact</div>
                  <div className={'contactHead'}>General business inquiry?</div>
                  <div className={'contactBody'}>cruelwindblows@gmail.com</div>
                  <div className={'contactHead'}>Looking to speak with the director?</div>
                  <div className={'contactBody'}>robking686@gmail.com</div>
                  <div className={'contactHead'}>Or contact the production company?</div>
                  <div className={'contactBody'}>4square@4square.com</div>
              </div>
          </div>
      </div>
)
    ;
}

export default App;
